<template>
  <v-dialog width="700" v-model="value">
    <v-sheet class="py-6 px-8"  >

      <div class="d-flex justify-space-between align-center mt-2 ">
        <h1 class="text-h4 font-weight-light">Підтвердження операції</h1>
        <v-btn plain icon  @click="value = false"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-sheet  class="pt-16 pb-8 px-6"  >
        <h2 class="font-weight-light text-center"> {{ confirmationText }}</h2>
        <h3 class="text-center mt-6"> {{ data.data.order_id }}
        </h3>
      </v-sheet>
      <!-- Navigation-->
      <div class="d-flex justify-end mt-5 mb-3">
        <v-btn  :disabled="loading"  @click="value = false"
                large class="mt-3 px-8" text rounded elevation="0"  >
          <h3 class="font-weight-light">Ні</h3>
        </v-btn>
        <v-btn :disabled="loading" :loading="loading"  @click="confirm"
               large class="mt-3 px-8" rounded outlined elevation="0"  >
          <h3 class="font-weight-light">Так</h3>
        </v-btn>
      </div>

    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "orderEditConfirmation",
  props : ['value','data'],
  data(){
    return {
      loading : false
    }
  },
  computed : {
    confirmationText(){
      let text = ''
      switch(this.data.type) {
        case 'cancel' :          text = `Ви впевнені що хочете скасувати замовлення ?`; break;
        case 'delivery_status' : text = `Підтвердіть зміну статуса доставки замовлення`; break;
        case 'payment_status'  : text = `Підтвердіть зміну статуса оплати замовлення`; break;
      }
      return text
    }
  },
  watch: {
    value(value){
      this.$emit('input',value)
    }
  },
  methods : {
    confirm(){
      this.$emit('onConfirm',this.data)
      this.$emit('input',false)
    }
  }
}
</script>

<style scoped>

</style>